import Logo from '../assets/img/logo.svg'
import { Link, Navigate } from 'react-router-dom'
import { useStateContext } from '../contexts/ContextProvider'
import List from './../assets/img/list.svg'
import Logout from './../assets/img/exit.svg'
import ModalYesNo from './ModalYesNo'
import { useState, useEffect } from 'react'
import api from '../services/api'

import Medicamentos from '../assets/img/pilulas.svg'
import LogoutIMGModal from '../assets/img/logoutImg.svg'

import Report from '../assets/img/relatorio.svg'
import Money from '../assets/img/fatura.svg'

import BarChart from '../assets/img/bar-chart.svg'

function Menu({ screen }) {
    const { token } = useStateContext()
    const [showModal, setShowModal] = useState(false)
    const [username, setUsername] = useState(null)

    function getFirstNames(nomeCompleto) {
        const palavras = nomeCompleto.split(' ');
        const preposicoes = ['de', 'da', 'do', 'dos', 'das'];
      
        // Função para capitalizar a primeira letra de cada nome
        const capitalize = (nome) => nome.charAt(0).toUpperCase() + nome.slice(1).toLowerCase();
      
        // Verifica se o segundo nome é uma preposição
        if (preposicoes.includes(palavras[1]?.toLowerCase())) {
          return palavras.slice(0, 3).map((palavra) => {
            return preposicoes.includes(palavra.toLowerCase()) ? palavra.toLowerCase() : capitalize(palavra);
          }).join(' ');
        }
      
        // Retorna os dois primeiros nomes capitalizados
        return palavras.slice(0, 2).map(capitalize).join(' ');
      }

    useEffect(() => {
        var expires = new Date(localStorage.getItem('EXPIRES_TOKEN'))
        var atual_date = new Date()

        if ((atual_date >= expires)) {
            localStorage.removeItem('ACCESS_TOKEN')
            window.location.reload()
        }
    }, []);

    useEffect(() => {
        api.get('api/user/authenticated')
            .then((response) => {
                const firstNames = getFirstNames(response.data.data.name)
                setUsername(firstNames)
            })
            .catch((err) => {
                console.error(err)
            })
    }, [])

    if (!token) {
        return <Navigate to="/login" />
    }

    const onLogout = () => {
        localStorage.removeItem('ACCESS_TOKEN')
        window.location.reload()
    };

    const sidebarToggle = () => {
        const sidebarToggleConst = document.body.querySelector('#sidebarToggle');
        if (sidebarToggleConst) {
            document.body.classList.toggle('sb-sidenav-toggled');
            localStorage.setItem('sb|sidebar-toggle', document.body.classList.contains('sb-sidenav-toggled'));
        }
    }

    return (
        <>
            <nav className="sb-topnav navbar navbar-expand navbar-dark bg-dark">
                <a style={{ display: 'flex' }} className=" align-items-center navbar-brand ps-3 justify-content-center" href="/"> <img style={{ marginRight: 0 }} width={140} alt='logo' src={Logo} /></a>
                <button onClick={sidebarToggle} className="btn btn-link btn-sm order-1 order-lg-0 me-4 me-lg-0" id="sidebarToggle" href="#!">
                    <i className="fas fa-bars"></i>

                    <img alt='menu' width={30} src={List} />
                </button>
                <div className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0">

                </div>
                <span className=' btn btn-link navbar-nav ms-auto ms-md-0 me-3 me-lg-2'>
                </span>
            </nav>
            <div id="layoutSidenav">
                <div id="layoutSidenav_nav">
                    <nav className="sb-sidenav accordion sb-sidenav-dark" id="sidenavAccordion">
                        <div className="sb-sidenav-menu px-2">
                            <div className="nav">
                                <div className="sb-sidenav-menu-heading">Principal</div>

                                <Link onClick={() => {
                                    setTimeout(() => {
                                        if (window.screen.width < 993) {
                                            sidebarToggle()
                                        }
                                    }, 500);
                                }} className="nav-link link-menu align-items-start" to="/dashboard"><img className='img-menu' alt='dashboard' src={BarChart} /> <span>Dashboard</span> </Link>

                                <Link onClick={() => {
                                    setTimeout(() => {
                                        if (window.screen.width < 993) {
                                            sidebarToggle()
                                        }
                                    }, 500);

                                }} className="nav-link link-menu align-items-start" to="/medicines"><img className='img-menu' alt='customers' src={Medicamentos} /><span>Medicamentos</span> </Link>

                                <Link onClick={() => {
                                    setTimeout(() => {
                                        if (window.screen.width < 993) {
                                            sidebarToggle()
                                        }
                                    }, 500);
                                }} className="nav-link link-menu align-items-start" to="/financial" ><img className='img-menu' alt='dashboard' src={Money} /><span>Financeiro</span> </Link>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }} className="align-items-center sb-sidenav-footer justify-content-between">
                            <div>
                                <div className="small">Logado como:</div>
                                {username}
                                <span className=' btn btn-link navbar-nav ms-auto ms-md-0 me-3 me-lg-2'>
                                </span>
                            </div>
                            <img onClick={() => setShowModal(true)} style={{ cursor: 'pointer', width: 25 }} alt='logout' src={Logout} />

                        </div>
                    </nav>
                </div>
                <div id="layoutSidenav_content">
                    <main>
                        {screen}
                    </main>

                </div>
            </div>
            <ModalYesNo title={'Logout'} show={showModal} message={"Tem certeza que deseja sair?"} imgSVG={LogoutIMGModal} setShow={setShowModal} afirmativeFunc={onLogout} affirnName={"Sair"} negName={"Voltar"} />
        </>
    )
}
export default Menu