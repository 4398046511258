import { useState } from "react";
import Menu from "../../components/Menu";
import Back from '../../assets/img/de-volta.svg'

import MedicinesList from "../../components/MedicinesList";
import More from '../../assets/img/mais.svg'
import MedicinePills from '../../assets/img/pilulas-black.svg'
import { Link } from 'react-router-dom'

export default function Medicines() {
  const [search, setSearch] = useState('')

  const screenTela =
    <div className="ms-4 mt-3">
      <div className="row row-container">

        <h1 className="col-6 col-sm-6 col-lg-6 name-screen my-4"> <img style={{ width: '40px' }} alt="svg" src={MedicinePills} /> <span className="name-screen-title" >Medicamentos</span> </h1>
        <div className="col-6 col-sm-6 col-lg-6 name-search my-4">
          <input value={search} onChange={(value) => {
            setSearch(value.target.value)
          }} type="text" placeholder="Pesquisar Medicamentos" className="form-control input-pesquisa" />
        </div>

        <MedicinesList search={search} />

      </div>
      <Link to="/dashboard">
        <div className="button-back-list">
          <img alt="mais" src={Back} />
        </div>
      </Link>
      <Link to="add">
        <div className="button-add">
          <img alt="mais" src={More} />
        </div>
      </Link>
    </div>

  return (
    <Menu screen={screenTela} />
  )
}
