import { createContext, useContext, useState } from 'react';

const StateContext = createContext({
    token: null,
    setToken: () => { }
});

export const ContextProvider = ({ children }) => {

    const [token, _setToken] = useState(null);

    const setToken = (token) => {
        _setToken(token)

        if (token) {
            // Logica para token nos arquivos de api.js apiForm.js recarregarem novamente
            if (localStorage.getItem("ACCESS_TOKEN") === null) {
                localStorage.setItem('ACCESS_TOKEN', token);
                //navigate('/dashboard')
                //window.location.reload()
                window.location = '/dashboard';
            } else {
                localStorage.setItem('ACCESS_TOKEN', token);
            }

        } else {
            localStorage.removeItem('ACCESS_TOKEN');
        }
    }

    useState(() => {
        var tokenLocal = localStorage.getItem('ACCESS_TOKEN')
        var expires = new Date(localStorage.getItem('EXPIRES_TOKEN'))
        var atual_date = new Date()
        
        if (tokenLocal && (atual_date <= expires)) {
            setToken(tokenLocal)
        } else {
            setToken(null)
        }

    }, [])

    return (
        <StateContext.Provider value={{
            token,
            setToken
        }}>
            {children}
        </StateContext.Provider>
    )
};

export const useStateContext = () => useContext(StateContext);
