const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/

export function emailValid(email) {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
    return regex.test(email);

}
export function PhoneValid(phone) {
    // Expressão regular para verificar se o número de telefone está no formato (xx) xxxxx-xxxx
    const regex = /^\(\d{2}\) \d{4} - \d{4}$/;
    return regex.test(phone);
}
export function PhoneMobile(mobile) {
    // Expressão regular para verificar se o número de telefone está no formato (xx) xxxxx-xxxx
    const regex = /^\(\d{2}\) \d{1} \d{4} - \d{4}$/;
    return regex.test(mobile);
}
export function CNPJValid(value) {
    if (!value) return false;

    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string';
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value);

    // Elimina valor de tipo inválido
    if (!validTypes) return false;

    // Filtro inicial para entradas do tipo string
    if (isString) {
        // Teste Regex para veificar se é uma string apenas dígitos válida
        const digitsOnly = /^\d{14}$/.test(value);
        // Teste Regex para verificar se é uma string formatada válida
        const validFormat = regexCNPJ.test(value);
        // Verifica se o valor passou em ao menos 1 dos testes
        const isValid = digitsOnly || validFormat;

        // Se o formato não é válido, retorna inválido
        if (!isValid) return false;
    }

    // Elimina tudo que não é dígito
    const numbers = this.matchNumbers(value);

    // Valida a quantidade de dígitos
    if (numbers.length !== 14) return false;

    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)];
    if (items.length === 1) return false;

    // Separa os 2 últimos dígitos verificadores
    const digits = numbers.slice(12);

    // Valida 1o. dígito verificador
    const digit0 = this.validCalc(12, numbers);
    if (digit0 !== digits[0]) return false;

    // Valida 2o. dígito verificador
    const digit1 = this.validCalc(13, numbers);
    return digit1 === digits[1];
}
export function validCalc(x, numbers) {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
}
export function matchNumbers(value) {
    const match = value.toString().match(/\d/g);
    return Array.isArray(match) ? match.map(Number) : [];
}
export function DateDDMMInvalid(data) {
    if (data === null || data === "") {
        return false;
    } else {
        // Verifica se ambas as datas têm o formato correto (dd/mm)
        const regex = /^(\d{2})\/(\d{2})$/;
        if (!regex.test(data)) {
            return true; // Formato incorreto
        }


        // Divida a string em dia e mês
        const partes = data.split('/');
        if (partes.length !== 2) {
            return true;
        }

        const dia = parseInt(partes[0], 10);
        const mes = parseInt(partes[1], 10);

        // Verifique se o dia e o mês estão dentro dos limites apropriados
        if (dia < 1 || dia > 31 || mes < 1 || mes > 12) {
            return true;
        }

        const year = new Date().getFullYear();
        // Verifique se o mês tem a quantidade correta de days
        const daysNoMes = new Date(year, mes, 0).getDate();


        if (dia > daysNoMes) {
            return true;
        }

        return false;
    }
}
export function IntervalInvalidDateDDMM(inicial_date, final_date) {
    if (inicial_date === null || final_date === null || inicial_date === "" || final_date === "") {
        this.validarDataDDMM(final_date);
        return false;
    } else {
        // Verifica se ambas as datas têm o formato correto (dd/mm)
        const regex = /^(\d{2})\/(\d{2})$/;
        if (!regex.test(inicial_date) || !regex.test(final_date)) {
            return true; // Formato incorreto
        }

        // Divide as datas em dia e mês
        const inicialPart = inicial_date.split('/');
        const finalPart = final_date.split('/');
        const inicialDay = parseInt(inicialPart[0], 10);
        const inicialMounth = parseInt(inicialPart[1], 10);
        const finalDay = parseInt(finalPart[0], 10);
        const finalMounth = parseInt(finalPart[1], 10);

        // Verifica se o dia e o mês estão dentro dos limites apropriados
        if (inicialDay < 1 || inicialDay > 31 ||
            inicialMounth < 1 || inicialMounth > 12 ||
            finalDay < 1 || finalDay > 31 ||
            finalMounth < 1 || finalMounth > 12) {
            return true; // Dia ou mês inválido
        }

        if (inicialMounth > finalMounth || (inicialMounth === finalMounth && finalDay < inicialDay)) {
            return true;
        }

        var year = new Date().getFullYear();
        // Verifica se o dia não excede o número de days no mês
        const daysIninicialMounth = new Date(year, inicialMounth, 0).getDate();
        const daysInfinalMounth = new Date(year, finalMounth, 0).getDate();

        if (inicialDay > daysIninicialMounth || finalDay > daysInfinalMounth) {
            return true; // Dia excede o número de days no mês
        }

        return false; // Ambas as datas são válidas
    }

}
export function CPFValid(cpf) {
    var rev;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false;
    // Valida 1o digito	
    var add = 0;
    for (var i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito	
    add = 0;
    for (i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(10)))
        return false;
    return true;
}
export function DateDDMMAAAAInvalid(date) {

    // Verifica se ambas as datas têm o formato correto (dd/mm/aaaa)
    const regex = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    if (!regex.test(date)) {
        return true; // Formato incorreto
    }

    // Divida a string em dia e mês
    const parts = date.split('/');

    if (parts.length !== 3) {
        return true;
    }

    const day = parseInt(parts[0], 10);
    const mounth = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Verifique se o dia e o mês estão dentro dos limites apropriados
    if (day < 1 || day > 31 || mounth < 1 || mounth > 12 || year <= 1900) {
        return true;
    }

    // Verifique se o mês tem a quantidade correta de days
    const daysInMounth = new Date(year, mounth, 0).getDate();

    if (day > daysInMounth) {
        return true;
    }

    return false;
}


