import React from "react";
import Spinner from 'react-bootstrap/Spinner';

function Loading() {
    return (
        <div style={{marginTop:'20px'}} className='col-12 flex d-flex justify-content-center'>
            <Spinner size="xl" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>

    )
}

export default Loading