import React from "react";
import { useNavigate } from "react-router-dom";

function FooterAdd() {
    const navigate = useNavigate()
    return (
        <div className="bottom-edit-menu justify-content-end">
            <div>
                 <button onClick={()=>{
                    navigate(-1)
                 }} type="button" className="generic-button button-back">Voltar</button>

                <button type="submit" className="generic-button button-save">Salvar</button>
            </div>
        </div>
    )
}

export default FooterAdd