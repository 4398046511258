import React, { useState } from "react";
import { formatCPF, formatPhone } from '../utils/formats'
import { faCheck, faCreditCard, faHandHoldingDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPix } from "@fortawesome/free-brands-svg-icons";
import { Tooltip, OverlayTrigger, Spinner } from 'react-bootstrap'
import moment from "moment";
import faSend from './../assets/icons/entrega-rapida.svg'
import faReceived from './../assets/icons/recebido.svg'
import api from "../services/api";
import './componentsCss/SaleCard.css'

export default function SaleCard({ sale, screen, index, sales, setSales }) {

    const [loading, setLoading] = useState(false)

    const tooltip = (text) => {
        return (
            <Tooltip id="tooltip">
                {text}
            </Tooltip>
        );
    }

    const onChangeStatus = async (value, index) => {
        setLoading(true)

        // console.log('ID', sales[index].id)
        // console.log('NEW VALUE', value)
        // console.log('PH_STATUS', sales[index].ph_status)

        const data = {
            id: sales[index].id,
            status: value
        }

        await api.put('api/attendances/update/pharmacy', JSON.stringify(data))
        .then(response => {
            let updatedSales = [...sales];
            updatedSales[index] = {...updatedSales[index], ph_status: value}
            setSales(updatedSales)
        })
        .catch(error => {
            console.error(error)
        })
        .finally(() => {
            setLoading(false);
        })

        // setTimeout(() => {
        //     let salesPrev = sales
        //     salesPrev[index].status = value
        //     setSales(salesPrev)
        //     setLoading(false)
        // }, 1000);
    }

    const statusInfo = () => {
        var value

        switch (sale.ph_status) {
            case 0:
            case null:
                value = {
                    text: 'vendido',
                    color: 'separation',
                    icon: () => {
                        if (loading) {
                            return (
                                <span className="button-icon-action second">
                                    <Spinner size="sm" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </span>)
                        }
                        return (
                            <OverlayTrigger placement="auto" overlay={tooltip('Confirmar Pedido')}>
                                <span onClick={() => {
                                    onChangeStatus(1, index)
                                }} className="button-icon-action second">
                                    <FontAwesomeIcon icon={faCheck} color="white" />
                                </span>
                            </OverlayTrigger>)
                    }
                }
                break;
            case 1:
                value = {
                    text: 'em dispensação',
                    color: 'in-separation',
                    icon: () => {
                        if (loading) {
                            return (
                                <span className="button-icon-action second">
                                    <Spinner size="sm" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </span>)
                        }
                        return (
                            <OverlayTrigger placement="auto" overlay={tooltip('Entregar')}>
                                <span onClick={() => {
                                    onChangeStatus(2, index)
                                }} className="button-icon-action second">
                                    <img alt="send" width={25} src={faSend} />
                                </span>
                            </OverlayTrigger>)
                    }
                }
                break;
            case 2:
                value = {
                    text: 'entregando',
                    color: 'delivering',
                    icon: () => {
                        if (loading) {
                            return (
                                <span className="button-icon-action second">
                                    <Spinner size="sm" animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                </span>)
                        }
                        return (
                            <OverlayTrigger placement="auto" overlay={tooltip('Entregue')}>
                                <span onClick={() => {
                                    onChangeStatus(3, index)
                                }} className="button-icon-action second">
                                    <img alt="received" width={25} src={faReceived} />
                                </span>
                            </OverlayTrigger>)
                    }
                }
                break;
            case 3:
                value = {
                    text: 'entregue',
                    color: 'delivered',
                    icon: () => {
                        return (
                            <></>)
                    }
                }
                break;
            case 4:
                value = {
                    text: 'cancelado',
                    color: 'canceled',
                    icon: () => {
                        return (
                            <></>)
                    }
                }
                break;
            default:
                value = {
                    text: '',
                    color: 'none',
                    icon: () => {
                        return (<></>)
                    }
                }
                break;
        }

        return value
    }

    return (
      <div className="row card-list-col-12 sale-card">
        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 col-item">
          <span style={{ fontWeight: 700 }}>{sale.customer_name}</span>
          <span>{formatCPF(sale.customer_cpf)}</span>
          <span>{formatPhone(sale.customer_mobile)}</span>
          <br></br>
          <span>{sale.payment_time}</span>
        </div>

        <div className="col-12 col-sm-6 col-lg-4 col-xl-3 col-item">
          <span>{sale.street}</span>
          <span>
            {sale.city} - {sale.state}
          </span>
          <span>{sale.complement}</span>
        </div>

        <div className="col-12 col-sm-3 col-lg-2 col-item">
          {sale.payment_type === "PIX" && (
            <span>
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                icon={faPix}
                color="white"
              />
              {sale.payment_type}
            </span>
          )}

          {sale.payment_type.includes("CRÉDITO") && (
            <span>
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                icon={faCreditCard}
                color="white"
              />
              {sale.payment_type}
            </span>
          )}

          {sale.payment_type === "DÉBITO" && (
            <span>
              <FontAwesomeIcon
                style={{ marginRight: 5 }}
                icon={faCreditCard}
                color="white"
              />
              {sale.payment_type}
            </span>
          )}
        </div>

        <div className="col-12 col-sm-3 col-lg-2 col-xl-1 col-item">
          <span>
            R${" "}
            {sale.value_paid.toLocaleString("pt-BR", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </span>
        </div>

        <div className="col-12 col-sm-3 col-lg-6 col-xl-2 row-item">
          {screen !== "reportbalance" ? (
            <span
              style={{ marginBottom: 10, marginTop: 10 }}
              className={"status-info " + statusInfo(sale).color}
            >
              {statusInfo(sale).text}
            </span>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {sale.date_paid !== null && (
                  <>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "900",
                        opacity: 0.6,
                        paddingTop: 5,
                      }}
                    >
                      data de repasse
                    </span>
                    <span>{moment(sale.date_paid).format("DD/MM/YYYY")}</span>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {sale.value_paid === null ? (
                  <span
                    style={{
                      fontSize: 14,
                      fontWeight: "900",
                      opacity: 0.6,
                      paddingTop: 5,
                      paddingBottom: 5,
                    }}
                  >
                    não pago
                  </span>
                ) : (
                  <>
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: "900",
                        opacity: 0.6,
                        paddingTop: 5,
                      }}
                    >
                      valor pago
                    </span>
                    <span>
                      R${" "}
                      {sale.value_paid.toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    </span>
                  </>
                )}
              </div>
            </>
          )}
        </div>

        <div className="col-12 col-sm-3 col-lg-6 col-xl-1 row-item">
          {screen !== "reportsale" &&
            screen !== "reportbalance" &&
            statusInfo(sale).icon()}

          <OverlayTrigger placement="auto" overlay={tooltip("Orçamento")}>
            <span
              onClick={() => {
                api
                  .get("api/attendances/" + sale.id + "/prescriptionitems")
                  .then((response) => {
                    const path = response.data.path;
                    window.open(
                      "https://codex.inkless.digital/storage/" + path,
                      "_blank"
                    );
                  })
                  .catch((error) => {
                    console.error(error);
                  });
              }}
              className="button-icon-action second"
            >
              <FontAwesomeIcon icon={faHandHoldingDollar} color="white" />
            </span>
          </OverlayTrigger>
        </div>
      </div>
    );
}