import React, { useEffect } from 'react'
import Menu from '../../components/Menu'
import SaleCard from '../../components/SaleCard'
import moment from 'moment'
import Swal from 'sweetalert2'
import ModalRepasse from '../../components/ModalRepasse/ModalRepasse'
// Relatorio de Vedas por periodo aqui aparecerao todas as vendas que foram realizadas de acordo com o periodo selecionado
export default function ReportSalesBalance() {
    const [initialDate, setInitialDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [finalDate, setFinalDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [modalRepasse, setModalRepasse] = React.useState(false)
    const [filters, setFilters] = React.useState({
        status: '0'
    })
    const [sales, setSales] = React.useState([])
    const [salesTotal, setSalesTotal] = React.useState([
        {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: null,
            date_paid: null,
            status: 1
        }, {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: 116.34,
            date_paid: '2024-05-12',
            status: 1
        },
        {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: null,
            date_paid: null,
            status: 1
        }, {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: 116.34,
            date_paid: '2024-05-12',
            status: 1
        },
        {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: null,
            date_paid: null,
            status: 1
        }, {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: 116.34,
            date_paid: '2024-05-12',
            status: 1
        },
        {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: null,
            date_paid: null,
            status: 1
        }, {
            customer_name: 'MARCOS VINICIUS RIBEIRO ALENCAR',
            customer_cpf: '07332485321',
            customer_mobile: '86998514018',
            cep: '54213120',
            street: 'Avenida Rio Branco do Sul Tavares',
            city: 'Teresina',
            commission: '10%',
            state: 'PI',
            complement: 'Proximo ao Bar',
            budget: 'https://demo.inkless.digital/storage/solicitation/1610582024050666392b4248c18.pdf',
            payment_type: 'PIX',
            value_total: 124.49,
            value_paid: 116.34,
            date_paid: '2024-05-12',
            status: 1
        }
    ])

    const [total, setTotal] = React.useState(124.49);

    const [totalPaid, setTotalPaid] = React.useState(116.34);

    const realizeRepasse = () => {
        var not_paid = sales.filter((elem) => {
            return elem.value_paid === null
        })

        if (not_paid === null || not_paid.length === 0) {
            return Swal.fire({
                title: "Operação não permitida",
                text: 'Não existem pagamentos para serem realizados.',
                icon: 'warning',
                confirmButtonColor: '#56CCF2',

            })
        }

        setModalRepasse(true)

    }

    useEffect(() => {
        setSales(salesTotal.filter(elem => {
            return filters.status === '0' || (filters.status === '1' && elem.value_paid !== null) || (filters.status === '2' && elem.value_paid === null)
        }))
    }, [filters])

    const screenTela =
        <div id='dashboard' className='screenContainer'>
            <div className='row'>
                <h1 className='title-screen name-screen-title col-12 col-sm-6'>Relatório de Repasse</h1>
                <div style={{ gap: 10, paddingBottom: 10 }} className='col-12 col-sm-6 flex-end'>

                    <div className='input-container'>
                        <label className="form-label-input">Status</label>

                        <select onChange={(value) => {
                            setFilters({
                                ...filters,
                                status: value.target.value
                            })
                        }} className="form-control input-pesquisa-non-focus" id="inputGroupSelect01">
                            <option selected value="0">Todos</option>
                            <option value="1">Pagos</option>
                            <option value="2">Não Pagos</option>
                        </select>
                    </div>

                    <div className='input-container'>
                        <label className="form-label-input">Data Inicial</label>

                        <input style={{ maxWidth: 165 }} value={initialDate} onChange={(value) => {
                            if (value.target.value === '') {
                                setInitialDate(initialDate)

                            } else {
                                setInitialDate(value.target.value)
                            }
                        }} type="date" max={finalDate} placeholder="Pesquisar Cliente" className="form-control input-pesquisa-non-focus" />
                    </div>

                    <div className='input-container'>
                        <label className="form-label-input">Data Final</label>

                        <input min={initialDate} style={{ maxWidth: 165 }} value={finalDate} onChange={(value) => {
                            if (value.target.value === '') {
                                setFinalDate(finalDate)

                            } else {
                                setFinalDate(value.target.value)
                            }
                        }} type="date" placeholder="Pesquisar Cliente" className="form-control input-pesquisa-non-focus" />
                    </div>

                </div>
            </div>

            <div className="list-fixed-header-footer">
                {
                    sales.map(elem => {
                        return (
                            <SaleCard screen={'reportbalance'} sale={elem} />
                        )
                    })
                }
                {/* {loading ? <Loading /> : <></>} */}
            </div>

            <div className='row row-footer-report'>

                <div className='col-12'>
                    <div>
                        <button onClick={realizeRepasse} className='btn btn-primary btn-generic'>Repasse</button>
                    </div>
                    <div>
                        Total: <span>R${total.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} </span>
                    </div>

                    <div>
                        Total Pago: <span>R${totalPaid.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })} </span>
                    </div>
                </div>

            </div>

            <ModalRepasse show={modalRepasse} setShow={setModalRepasse} affirmFunction={() => {
                setModalRepasse(false)
                return Swal.fire({
                    title: "Sucesso",
                    text: 'Repasse de valores realizado com sucesso.',
                    icon: 'success',
                    confirmButtonColor: '#56CCF2',

                })
            }} />

        </div>

    return (

        <Menu screen={screenTela} />


    )
}
