import { createBrowserRouter, Navigate } from 'react-router-dom';
import DefaultLayout from '../components/DefaultLayout.js';
import GuestLayout from '../components/GuestLayout.js';
import Dashboard from '../views/dashboard/Dashboard.js';
import Login from '../views/login/Login.js';
import NotFound from '../views/NotFound.js';
import Medicines from '../views/medicines/Medicines.js';
import MedicinesEdit from '../views/medicines/MedicinesEdit.js';
import MedicinesAdd from '../views/medicines/MedicinesAdd.js';
import Financial from '../views/financial/Financial.js';
import ReportSales from '../views/financial/ReportSales.js';
import ReportBalance from '../views/financial/ReportSalesBalance.js';

// Rotas de "Medicines"
const medicinesRoutes = [
    {
        path: '/medicines',
        element: <Medicines />,
    },
    {
        path: '/medicines/:id',
        element: <MedicinesEdit />
    },
    {
        path: '/medicines/add',
        element: <MedicinesAdd />
    },
];

// Rotas de "Financial"
const financialRoutes = [
    {
        path: '/financial',
        element: <Financial />,
    },
    {
        path: '/financial/report/sales',
        element: <ReportSales />,
    },
    {
        path: '/financial/report/balance',
        element: <ReportBalance />,
    }
];

        const router = createBrowserRouter([
            {
                path: '/',
                element: <DefaultLayout />,
                basename: '/',
                children: [
                    {
                        path: '/',
                        element: <Navigate to="/dashboard" />
                    },
                    {
                        path: '/dashboard',
                        element: <Dashboard />
                    },
                    ...financialRoutes,
                    ...medicinesRoutes
                ]
            },
            {
                path: '/',
                element: <GuestLayout />,
                children: [
                    {
                        path: '/login',
                        element: <Login />
                    },
                ]
            },
            {
                path: '*',
                element: <NotFound />
            },

        ])

export default router;
