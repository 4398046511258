import { useState, useEffect } from 'react'
import './componentsCss/ListCardAgreements.css'
import api from '../services/api'
import Loading from './Loading'
import { Link } from 'react-router-dom'
import './componentsCss/ListCardShoppers.css'
import './componentsCss/MedicinesList.css'

function MedicinesList({ search }) {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [finalPage, setFinalPage] = useState(null)

    const handleScroll = (event) => {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - 90 && !loading && !(page - 1 === finalPage)) {
            getData()
        }
    }

    const getData = (exception = false, page2 = null) => {
        // Já carregamos todas as páginas disponíveis ou esta carregando
        if (((page > finalPage && finalPage !== null) || loading) && exception === false) {
            return;
        }

        setLoading(true)

        if (search !== '') {
            api.get('/api/pharmacydrugs/search/' + search + '?page=' + String(page2 === null ? page : page2)).then((response) => {
                setData(page2 === null ? data.concat(response.data.data) : response.data.data);
                setFinalPage(response.data.meta.last_page)
                setPage(page + 1)
            }).catch((error) => {
                console.error('Error fetching shoppers:', error);
            }).finally(() => {
                setLoading(false)
            })

        } else {
            
            api.get('/api/pharmacydrugs?page=' + String(page)).then((response) => {
                setData(data.concat(response.data.data))
                setFinalPage(response.data.last_page)
                setPage(page + 1)
            }).catch((error) => {
                console.error('Error fetching agreements:', error);
            }).finally(() => {
                setLoading(false)
            })
        }
    }

    useEffect(() => {
         if (search === "") {
            setFinalPage(null)
            setPage(1)
            setData([])

            getData()

         } else {
            getData(true, 1)
        }
    }, [search])

    useEffect(() => {
        var el = document.getElementsByClassName('scroll-list-container')[0]
        if (el.scrollHeight <= window.innerHeight) {
            getData()
        }
    }, [page])

    return (
        <div onScroll={handleScroll} className='container-scroll'>
            <div className="row row-subcontainer scroll-list-container">
                {
                    data.length > 0 ? data.map((item, index) => {
                        return (
                        <Link className='col-12 col-sm-6 col-md-6 col-lg-4 px-3' key={index} to={'/medicines/' + item.id}>
                            <div className='card-list-shopper'>

                                <div className='col-12'>
                                    <span className='card-list-shopper-name'>{item.name}</span>
                                    <span className='card-list-shopper-cnpj_cpf'>{item.dcb_description}</span>
                                    <span className='card-list-shopper-mobile'>{item.presentation}</span>
                                </div>

                            </div>
                        </Link>
                        )}) : 
                        !loading ? <div><h6 style={{ textAlign: 'center', marginTop: '0px' }}>Sem Medicamentos</h6></div> : console.info("loading")
                }
                {loading ? <Loading /> : <></>}
            </div>
        </div>

    )
}

export default MedicinesList;