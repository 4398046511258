import React from "react";
import { Modal } from "react-bootstrap";
import style from './ModalRepasse.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export default function ModalRepasse({ show, setShow, affirmFunction }) {
    return (
        <Modal size='md' show={show} onHide={() => setShow(false)} >
            <Modal.Body className={style.modalBodyRepasse} >
                <h4 className={style.title}>Realizar Repasse de Valores</h4>
                <div className={style.infoModalRepasse}>
                    <div>
                        <span className={style.item}>Valor Total:</span>
                        <span className={style.valor}> R$ 124,49</span>
                    </div>

                    <div>
                        <span className={style.item}>Comissão:</span>
                        <span className={style.valor}> 10%</span>
                    </div>

                    <div>
                        <span className={style.item}>Valor à ser repassado:</span>
                        <span className={style.valor}> R$ 115,49</span>
                    </div>

                    <div>
                        <span className={style.item}>Data:</span>
                        <span className={style.valor}>{moment().format('DD/MM/YYYY')}</span>
                    </div>

                    <div>
                        <div className={style.cardUser}>
                            <div className={style.containerImage}>
                                <FontAwesomeIcon icon={faUser} color='#00468B' fontSize={24} />
                            </div>
                            <div className={style.containerInfo}>
                                <span className={style.item}>MARCOS VINICIUS ALENCAR</span>

                            </div>

                        </div>
                    </div>

                    <div>
                        <span style={{ paddingTop: 15 }} className={style.warning}>Ao realizar o repasse dos valores você está se responsabilizando sobre dados gerados.</span>
                    </div>
                </div>

                <div className="buttons">
                    <button className='buttonModal' onClick={affirmFunction}>Repassar</button>
                    <button className='buttonModal buttonModalNeg' onClick={() => setShow(false)}>Fechar</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}