import React, { useEffect } from 'react'
import Menu from '../../components/Menu'
import SaleCard from '../../components/SaleCard'
import moment from 'moment';
import api from '../../services/api';

// Relatorio de Vedas por periodo aqui aparecerao todas as vendas que foram realizadas de acordo com o periodo selecionado
export default function ReportSales() {
    const [initialDate, setInitialDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [finalDate, setFinalDate] = React.useState(moment().format('YYYY-MM-DD'));
    const [sales, setSales] = React.useState([])
    const [total, setTotal] = React.useState(124.49);

    useEffect(() => {
        async function getReports() {
            await api.post('api/prescription/list/items', JSON.stringify({
                date_start: initialDate,
                date_end: finalDate
            }))
                .then(response => {
                    const sales = response?.data?.schedulings?.data || [];
                    //console.log(sales);
                    const Sales = sales.map((sale) => {
                      return {
                        ...sale,
                        id: sale.id != null ? sale.id : "",
                        street:
                          sale.address != null ? sale.address : "Rua ????",
                        city: sale.city != null ? sale.city : "???????",
                        state: sale.state != null ? sale.state : "??",
                        complement:
                          sale.complement != null ? sale.complement : "",
                        payment_type:
                          sale.payment_type != null ? sale.payment_type : "PIX",
                        date_paid:
                          sale.date_paid != null
                            ? sale.date_paid
                            : "??/??/????",
                        value_total:
                          sale.value_total != null ? sale.value_total : "????",
                        value_paid:
                          sale.value_paid != null ? sale.value_paid : "????",
                        budget:
                          sale.budget != null
                            ? "https://codex.inkless.digital/storage/" +
                              sale.budget
                            : "",
                        payment_time:
                          sale.payment_time != null ? sale.payment_time : "",
                      };
                    });

              setSales(Sales);
                })
                .catch(error => {
                    console.error(error)
                })
        }

        getReports()

    }, [initialDate, finalDate])


    const screenTela =
        <div id='dashboard' className='screenContainer'>
            <div className='row'>
                <h1 className='title-screen name-screen-title col-12 col-sm-6'>Relatório de Vendas</h1>
                <div style={{ gap: 10, paddingBottom: 10 }} className='col-12 col-sm-6 flex-end'>
                    <div className='input-container'>
                        <label className="form-label-input">Data Inicial</label>

                        <input style={{ maxWidth: 165 }} value={initialDate} onChange={(value) => {
                            if (value.target.value === '') {
                                setInitialDate(initialDate)

                            } else {
                                setInitialDate(value.target.value)
                            }
                        }} type="date" max={finalDate} placeholder="Pesquisar Cliente" className="form-control input-pesquisa-non-focus" />
                    </div>

                    <div className='input-container'>
                        <label className="form-label-input">Data Final</label>

                        <input min={initialDate} style={{ maxWidth: 165 }} value={finalDate} onChange={(value) => {
                            if (value.target.value === '') {
                                setFinalDate(finalDate)

                            } else {
                                setFinalDate(value.target.value)
                            }
                        }} type="date" placeholder="Pesquisar Cliente" className="form-control input-pesquisa-non-focus" />
                    </div>

                </div>
            </div>
            <div className='row row-footer-report'>
                <div className='col-12'> Total: <span>R${total.toLocaleString('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })} </span></div>
            </div>
            <div className="list-fixed-header-footer">
                {
                    sales.map(elem => {
                        return (
                            <SaleCard screen={'reportsale'} sale={elem} key={elem.id} />
                        )
                    })
                }
                {/* {loading ? <Loading /> : <></>} */}
            </div>
        </div>
    return (

        <Menu screen={screenTela} />


    )
}
