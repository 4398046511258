import { useState } from 'react'
import Visivel from '../assets/img/visivel.svg'
import NaoVisivel from '../assets/img/nao_visivel.svg'
import Fade from 'react-bootstrap/Fade';



export default function InputPasswordView({ placeholderText, value, onChange }) {
    const viewOnImg = {
            width: 25,
            height: 25,
            position: 'absolute',
            right: 10,
            top:18,
            cursor:'pointer'
      };
    const [viewOn, setViewOn] = useState(false)

    return (

        <>
            <input className="form-control inputLogin" value={value} onChange={ev => onChange(ev.target.value)} id="inputPassword" type={viewOn ? 'text' : 'password'} placeholder='Senha' />
          
                    <Fade in={viewOn}>
                        <img alt='visível' className='viewOnImg' style={viewOnImg} onClick={() => { setViewOn(false) }} src={Visivel} />
                    </Fade> 
                    <Fade in={!viewOn}>
                        <img alt='não visível' style={viewOnImg} onClick={() => { setViewOn(true) }} src={NaoVisivel} />
                    </Fade>
        </>


    )
}

