import React from 'react'
import Logo from '../assets/img/logo.svg'
import './componentsCss/LoadingBlock.css'
function LoadingBlock() {
    return (
        <div className='loading-block'>
            <img alt="logo" src={Logo} />
        </div>
    )
}

export default LoadingBlock;