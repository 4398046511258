import React from "react";
import { useNavigate } from "react-router-dom";

function FooterEdit({ onClickDel, navigateRoute}) {
    const navigate = useNavigate()
    return (
        <div className="bottom-edit-menu">
            <button type="button" onClick={onClickDel} className="generic-button button-delete">Excluir</button>
            <div>
                <button type="button" onClick={ ()=>{
                    navigate(navigateRoute)
                }} className="generic-button button-back">Voltar</button>

                <button type="submit" className="generic-button button-save">Salvar</button>
            </div>
        </div>
    )
}

export default FooterEdit