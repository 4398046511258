import axios from 'axios';
import Globals from '../Globals';

const token = localStorage.getItem('ACCESS_TOKEN');

export default axios.create({
  baseURL: Globals.BASE_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Authorization",
    "Access-Control-Allow-Methods": "*",
    Authorization: token ? `Bearer ${token}` : '',
  }
});