export function formatDateDDMMYYYYHHMM(date) {
    // Data original
    // Converter para objeto Date
    const data = new Date(date);

    // Obter componentes da data
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero
    const ano = data.getFullYear();
    const hora = String(data.getHours()).padStart(2, '0');
    const minuto = String(data.getMinutes()).padStart(2, '0');

    // Formatar data e hora
    return `${dia}/${mes}/${ano} ${hora}:${minuto}`;

}
export function formatPhone(number) {
    if (number === undefined) return;
    const clear_number = number.replace(/\D/g, '');
    if (number.length === 10) {
        return clear_number.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2 - $3');

    } else if (number.length === 11) {
        return clear_number.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3 - $4');

    } else {
        return number;
    }
}
export function formatCPF(cpf) {
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.padStart(11, '0');
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}
export function formatCNPJ(value) {
    // Verifica se o valor é válido
    const valid = this.validCNPJ(value);

    // Se o valor não for válido, retorna vazio
    if (!valid) return '';

    // Elimina tudo que não é dígito
    const numbers = this.matchNumbers(value);
    const text = numbers.join('');

    // Formatação do CNPJ: 99.999.999/9999-99
    const format = text.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5'
    );

    // Retorna o valor formatado
    return format;
}