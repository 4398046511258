import React, { useState } from 'react'
import { useStateContext } from '../../contexts/ContextProvider'
import { emailValid } from '../../utils/validations'
import InputPasswordView from '../../components/InputPasswordView'
import InklessLogo from '../../assets/img/logo.svg'
import './Login.css'
import API from '../../services/api'
import { swal } from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [emailError, setEmailError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [errorLogin, setErrorLogin] = useState('')


    const { setToken } = useStateContext();

    const onSubmit = (ev) => {
        ev.preventDefault()
        setEmailError(!emailValid(email) ? 'Email Inválido' : '')

        setPasswordError(password.length < 8 ? 'Senha Inválida' : '')

        setErrorLogin('')
        if (emailValid(email) && !password.length < 8) {

            const data = JSON.stringify({
                grant_type: "password",
                client_id: 2,
                client_secret: "H51v91XkuwjFyg69gNuHU2srp1Kkk6WsbVHmWyWE",
                username: email,
                password: password,
                scope: ""
            })

            API.post(`/oauth/token`, data)
                .then(res => {

                    if (res.status === 200) {
                        var atual_date = new Date()

                        localStorage.setItem("EXPIRES_TOKEN", new Date(atual_date.getTime() + (res.data.expires_in * 1000)))
                        setToken(res.data.access_token)
                    }
                    else if (res.status === 401) {

                        swal("Atenção", res.data.message, "warning");

                    }

                })
                .catch(error => {
                    console.error(error)
                    if (error.response.status === 400 && error.response.data.message === "The user credentials were incorrect.") {
                        setEmailError("")
                        setPasswordError("")
                        setErrorLogin('Email ou Senha incorretos')
                    }
                    if (error.response.status === 500 || error.response.status === 404) {
                        setEmailError("")
                        setPasswordError("")
                        setErrorLogin('Erro no servidor contate o suporte')
                    }
                });

        }
    }

    return (
        <div className="login">
            <div id="layoutAuthentication">
                <div id="layoutAuthentication_content">
                    <main>
                        <div className="container ">
                            <div className="row justify-content-center">
                                <div className="col-xs-10 col-sm-8 col-md-7 col-lg-5 col-xl-4">
                                    <div className="card shadow-lg border-0 rounded-lg mt-5 loginCard">
                                        <div className="card-header justify-content-center">
                                            <img alt='logo' className='imgLogo' src={InklessLogo} />
                                            <span className='codexLogo'>Fármacia</span>
                                        </div>
                                        <div className="card-body ">
                                            <form onSubmit={onSubmit}>
                                                <div className="form-floating mb-3">
                                                    <input className="form-control inputLogin" id="inputEmail" type="text" placeholder="name@example.com" value={email} onChange={(ev) => setEmail(ev.target.value)} />
                                                    <label className='labelColor' htmlFor="inputEmail">Email</label>
                                                    <span className='error-msg'>{emailError}</span>
                                                </div>
                                                <div className="form-floating mb-3">
                                                    <InputPasswordView value={password} onChange={setPassword} />
                                                    <label className='labelColor ' htmlFor="inputPassword">Senha</label>
                                                    <span className='error-msg'>{passwordError}</span>
                                                    <span className='error-msg'>{errorLogin}</span>

                                                </div>
                                                <div className="d-flex align-items-center justify-content-end mt-4 mb-0">
                                                    <button type='submit' className="btn btn-primary btn-login">Entrar</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>

            </div>
        </div>
    )


}