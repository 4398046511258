import React from "react";
import { Modal } from "react-bootstrap";
import './componentsCss/ModalYesNo.css'

function ModalYesNo({ show, setShow, title, message, imgSVG, afirmativeFunc, affirnName, negName }) {
    return (
        <Modal show={show} onHide={() => setShow(false)} >
            <Modal.Body className="modalBody" >
                <h1 className="title">{title}</h1>
                <div className="imgContainer">
                    <img alt="logout" src={imgSVG} />
                </div>
                <h5>{message}</h5>
                <div className="buttons">
                    <button className='buttonModal' onClick={afirmativeFunc}>{affirnName}</button>
                    <button className='buttonModal buttonModalNeg' onClick={() => setShow(false)}>{negName}</button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ModalYesNo;